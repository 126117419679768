const militaryTimeToMeridiam = (time) => {
  const [hours, minutes] = time.split(":");
  let period = "am";
  let formattedHours = parseInt(hours);

  if (formattedHours >= 12) {
    period = "pm";
    formattedHours -= 12;
  }

  if (formattedHours === 0) {
    formattedHours = 12;
  }

  return `${formattedHours}:${minutes} ${period}`;
};

const militaryTimeToMeridiam2hour = (time) => {
  const [hours, minutes] = time.split(":");
  let period = "am";
  const orjinalHours = parseInt(hours);
  let formattedHours = orjinalHours;

  if (formattedHours >= 12) {
    period = "pm";
    formattedHours -= 12;
  }

  if (formattedHours === 0) {
    formattedHours = 12;
  }
  const newHour = `${formattedHours}:${minutes}`;
  const twoHoursLater = `${orjinalHours + 2}:${minutes}`;
  return `${newHour} ${period} to ${militaryTimeToMeridiam(twoHoursLater)}`;
};
const militaryTimeToMeridiam1halfhour = (time) => {
  const [hours, minutes] = time.split(":");
  let period = "am";
  const orjinalHours = parseInt(hours);
  let formattedHours = orjinalHours;

  if (formattedHours >= 12) {
    period = "pm";
    formattedHours -= 12;
  }

  if (formattedHours === 0) {
    formattedHours = 12;
  }
  const newHour = `${formattedHours}:${minutes}`;
  if (minutes === "30") {
    const twoHoursLater = `${orjinalHours + 2}:00`;
    return `${newHour} ${period} to ${militaryTimeToMeridiam(twoHoursLater)}`;
  } else {
    const twoHoursLater = `${orjinalHours + 1}:30`;
    return `${newHour} ${period} to ${militaryTimeToMeridiam(twoHoursLater)}`;
  }
};
// 2024-07-08 -> 07-08-2024
const dateToAmerican = (input) => {
  const [year, month, day] = input.split("-");
  return `${month}-${day}-${year}`;
};

export {
  militaryTimeToMeridiam,
  dateToAmerican,
  militaryTimeToMeridiam2hour,
  militaryTimeToMeridiam1halfhour,
};
