import { useEffect, useRef, useState } from "react";
import "./App.css";
import { militaryTimeToMeridiam2hour, militaryTimeToMeridiam1halfhour, dateToAmerican } from "./utils";

import { Amplify } from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: "eu-west-1:c19a9288-797a-4569-9452-e9cd0b85d7cd", // REQUIRED - Amazon Cognito Identity Pool ID
    region: "eu-west-1", // REQUIRED - Amazon Cognito Region
  },
});

// timetable = {
//     "2024-07-08&20:00": 2,
//     "2024-07-09&20:00": 2,
//     "2024-07-10&20:00": 2,
//     "2024-07-11&20:00": 2,
// }
// returns <select><option>...</option></select>
//
const SelectTimetable = (input) => {
  //   <select
  //   style={{ flex: "15%" }}
  //   id="timeslot"
  //   name="timeslot"
  //   required
  // >
  //   <option value="20:00">8 AM</option>
  //   <option value="13:00">1 PM</option>
  // </select>
  const cb = input.cb;
  const datesTimeslots = {};
  for (const [key, value] of Object.entries(timetable)) {
    if (value["person"] <= 0) {
      continue;
    }
    datesTimeslots[key] = value;
  }
  const timeslots = Object.keys(datesTimeslots);
  if (!timeslots.length) {
    return (
      <select
        onChange={(e) => cb(e.target.value)}
        style={{ flex: "15%" }}
        id="timeslot"
        name="timeslot"
        required
      >
        <option value="" hidden defaultValue>
          Full
        </option>
      </select>
    );
  }
  return (
    <select
      onChange={(e) => cb(e.target.value)}
      style={{ flex: "15%" }}
      id="timeslot"
      name="timeslot"
      required
    >
      <option value="" hidden defaultValue>
        Timeslot
      </option>
      {timeslots.map((option) => (
        <option key={option} value={option}>
          {dateToAmerican(option.split("&")[0])}{" "}
          {militaryTimeToMeridiam2hour(option.split("&")[1])}
        </option>
      ))}
    </select>
  );
};
// option = 2024-09-04&16:30
const pickRoute = (option) => {
  let shortOrLong = "short"
  const time = option.split("&")[1];
  if (time === "15:00") {
    shortOrLong = "short"
  }
  if (time === "16:30") {
    shortOrLong = "short"
  }
  if (parseInt(option.split("&")[0].split('-')[2]) > 7) {
    if (time === "15:00") {
      shortOrLong = "short"
    } else if (time === "16:30") {
      shortOrLong = "short"
    } else {
      shortOrLong = "long"
    }
  }
  if (shortOrLong === "short") {
    return militaryTimeToMeridiam1halfhour(time) + " Bus stops #2 and #3";
  } else {
    return militaryTimeToMeridiam2hour(time) + " Bus stops #1, #2 and #3";
  }
}

const SelectTimetable2 = (input) => {
  //   <select
  //   style={{ flex: "15%" }}
  //   id="timeslot"
  //   name="timeslot"
  //   required
  // >
  //   <option value="20:00">8 AM</option>
  //   <option value="13:00">1 PM</option>
  // </select>
  const cb = input.cb;
  const datesTimeslots = {};
  for (const [key, value] of Object.entries(timetable)) {
    // if (value["person"] <= 0) {
    //   continue;
    // }
    datesTimeslots[key] = value;
  }
  const timeslots = Object.keys(datesTimeslots);
  if (!timeslots.length) {
    return (
      <select
        onChange={(e) => {cb(e.target.value)}}
        style={{ flex: "15%" }}
        id="timeslot"
        name="timeslot"
        required
      >
        <option value="" hidden defaultValue>
          Full
        </option>
      </select>
    );
  }
  const options = [];
  let daysShown = 1;
  for (let i = 0; i < timeslots.length; i++) {
    if (daysShown > 10) {
      break;
    }
    const option = timeslots[i];
    const sameDayOptions = [option];
    for (let j = i + 1; j < timeslots.length; j++) {
      if (option.split("&")[0] === timeslots[j].split("&")[0]) {
        sameDayOptions.push(timeslots[j]);
      }
    }
    daysShown++;
    options.push(
      <>
        <p style={{fontWeight:'bold'}}>{dateToAmerican(option.split("&")[0])}</p>
        <div className="options">
          {sameDayOptions.map((option, index) => {
            return (
            <div className="option">
              <input
                onChange={(e) => {cb(e.target.value)}}
                type="radio"
                id={`option${i + index + 1}`}
                name="timeslot"
                value={option}
                required
              />
              <label htmlFor={`option${i + index + 1}`}>
                { pickRoute(option)} 
              </label>
            </div>
          )})}
        </div>
      </>
    
    );
    i += sameDayOptions.length -1;
  }
  return <>{options}</>;
  return (
    <div class="options">
      <div class="option">
        <input
          type="radio"
          id="option1"
          name="timeslot"
          value="option1"
          required
        />
        <label for="option1">
          6 AM - 8 AM <br />
          Stop #2 Only
        </label>
      </div>
      <div class="option">
        <input
          type="radio"
          id="option2"
          name="timeslot"
          value="option1"
          required
        />
        <label for="option1">
          6 AM - 8 AM <br />
          Stop #2 Only
        </label>
      </div>
      <div class="option">
        <input
          type="radio"
          id="option3"
          name="timeslot"
          value="option3"
          required
        />
        <label for="option1">
          6 AM - 8 AM <br />
          Stop #2 Only
        </label>
      </div>
    </div>
  );
};

const SelectPerson = ({ date, timeslot }) => {
  const options = [];
  const value = timetable[date + "&" + timeslot];
  for (let i = 0; i <= value["person"]; i++) {
    if (i === 11) break; // max i-1 person
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <select style={{ flex: "15%" }} id="person" name="person" required>
      {options}
    </select>
  );
};

const SelectAccPerson = ({ date, timeslot }) => {
  const options = [];
  const value = timetable[date + "&" + timeslot];
  for (let i = 0; i <= value["accPerson"]; i++) {
    if (i === 3) break;
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  return (
    <select style={{ flex: "15%" }} id="accPerson" name="accPerson" required>
      {options}
    </select>
  );
};

let timetable = {};
const getTimetable = async () => {
  try {
    const response = await fetch("https://api.dunespilot.flowride.ai/timeslots", {
      // Step 3
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    timetable = data;
  } catch (error) {
    console.error("Error retrieving timetable");
  }
};

const Map = () => {
  const [reservationMessage, setReservationMessage] = useState("");
  const [timeslotPicked, setTimeslotPicked] = useState(null);
  const mapRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Step 1

    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    try {
      const response = await fetch("https://api.dunespilot.flowride.ai/reserve", {
        // Step 3
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.status === 200) {
        // Step 4
        setReservationMessage(
          "Your booking is successful! Confirmation email has been sent. Your reservation is for " +
            dateToAmerican(data.timeslot.split("&")[0]) +
            " " +
            militaryTimeToMeridiam2hour(data.timeslot.split("&")[1]) +
            " for " +
            data.person +
            " person and " +
            data.accPerson +
            " wheelchair accessible seats."
        );
      } else if (response.status === 400) {
        setReservationMessage(
          "You already have a reservation, please cancel previous reservation via the ticket email"
        );
      } else if (response.status === 400) {
        setReservationMessage("Reservation failed please try again later");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setReservationMessage("Reservation failed please try again later");
    }
  };

  (function () {
    var newTitle = "ADASTEC Lightning Dunes Pilot";
    var titleTag = document.querySelector("title");
    titleTag.textContent = newTitle;
  })();

  return (
    <>
      <div ref={mapRef} id="map" className="fullheight-map" />
      <>
        <div className="panel panel-reservation">
          <div className="panel-body" id="panel-reservation">
            <div style={{ maxWidth: "500px" }}>
              <br />
              <h3 style={{ fontWeight: "600" }}>
                Book Your Ride for Automated Bus Pilot at Sleeping Bear Dunes
              </h3>
              <p style={{ padding: "10px" }}>
                Thank you for your interest in participating in the ADASTEC
                automated bus pilot at Sleeping Bear Dunes National Lakeshore!
              </p>
              <p style={{ padding: "10px" }}>
                The pilot will operate along the Pierce Stocking Scenic Drive
                (PSSD), stopping at up to three selected locations along the
                drive. The map below shows the locations where the bus may stop.
                During times of high traffic congestion, one or more stops may
                be skipped.
              </p>
              <img
                style={{ width: "100%" }}
                src="img/PSSDMapF.png"
                alt="PSSD Map"
              ></img>
              <p style={{ padding: "10px", color: "orange" }}>
                The bus will operate similar to a tour. It will stop and wait
                for a designated amount of time before continuing to the next
                stop. It is important that all passengers return to the bus in a
                timely manner to avoid being left behind and needing to hike
                back to your vehicle. If you cannot commit to participating in
                the full route as scheduled, please do not register to
                participate.
              </p>
              <br />

              {reservationMessage ? (
                <>
                <p style={{ color: "green", fontSize: "x-large" }}>
                  {reservationMessage}
                </p>
                <br/>
                <p style={{ color: "orange"}}>
                This is a pilot project that may have unplanned schedule changes, please check your email an hour before your scheduled time tour for any updates or cancelations.
                </p>
                </>
              ) : (
                <>
                  <p>
                    Please review the options below and register for the option
                    that is the best fit for your party. End times are
                    approximate and may be affected by traffic on the drive.
                  </p>
                  <form
                    className="dark-form"
                    id="qrForm"
                    onSubmit={handleSubmit}
                  >
                    <SelectTimetable2 cb={setTimeslotPicked} />
                    {timeslotPicked ? (
                      <>
                        <label for="person">Select number of seating</label>
                        <SelectPerson
                          date={timeslotPicked.split("&")[0]}
                          timeslot={timeslotPicked.split("&")[1]}
                        />
                        <label for="accPerson">
                          Select number of wheelchair accessible seats
                        </label>
                        <SelectAccPerson
                          date={timeslotPicked.split("&")[0]}
                          timeslot={timeslotPicked.split("&")[1]}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                    <input
                      type="text"
                      id="firstname"
                      name="firstName"
                      placeholder="First Name"
                      required
                    />
                    <input
                      type="text"
                      id="lastname"
                      name="lastName"
                      placeholder="Last Name"
                      required
                    />
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="e-mail"
                      required
                    />
                    <div class="tacbox">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        style={{ scale: "0.9" }}
                        required
                      />
                      <label
                        style={{
                          verticalAlign: "middle",
                          display: "inline",
                          fontSize: "small",
                        }}
                        for="checkbox1"
                      >
                        I affirm that all members of my group are at least 18
                        years of age or will be accompanied by a parent or legal
                        guardian.
                      </label>
                    </div>
                    <div class="tacbox">
                      <input
                        id="checkbox2"
                        type="checkbox"
                        style={{ scale: "0.9" }}
                        required
                      />
                      <label
                        style={{
                          verticalAlign: "middle",
                          display: "inline",
                          fontSize: "small",
                        }}
                        for="checkbox2"
                      >
                        {" "}
                        I affirm that I have reviewed and agree to the{" "}
                        <a href="/terms.html">Terms and Conditions</a>.
                      </label>
                    </div>
                    <button type="submit">Submit</button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

const App = () => {
  const [timetableSet, setTimetableSet] = useState(false);
  useEffect(() => {
    getTimetable().then(() => {
      setTimetableSet(true);
    });
  }, []);

  if (!timetableSet) {
    return <div>Loading...</div>;
  }

  return <Map />;
};

export default App;
